<template>
    <div class="ls-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/adver' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <slot></slot>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
    export default {
        props: {
            val: {}
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>
<style lang="scss"
       scoped>
    @import "index.scss";
</style>